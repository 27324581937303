<template>
  <article>
    <h2>{{ $t("pages.permission.data") }}</h2>
    <form class="permission-form mt-3 data-load" :class="{ 'sk-loading': isLoading }">
      <sk-spinner v-if="isLoading"></sk-spinner>
      <suc-form-has-error :form="form" />

      <div class="row">
        <div class="col">
          <absence-type-field
            id="absence-type-field"
            name="typeAppointment"
            :form="form"
            :required="true"
          />
        </div>
      </div>

      <div class="row" v-if="!absenceTypeRecognized">
        <div class="col" v-if="form.typeAppointment">
          <span class="text-danger">{{
            $t("pages.permission.absences.error_absence_configuration")
          }}</span>
        </div>
      </div>

      <div v-else>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <absence-start-day-field
              id="absence-start-date"
              name="dateStart"
              :form="form"
              :required="true"
              v-if="absenceTypeDay"
            />
          </div>
          <div class="col-sm-12 col-md-6">
            <absence-end-day-field
              id="absence-end-date"
              name="dateEnd"
              :form="form"
              :required="true"
              v-if="absenceTypeDay"
            />
          </div>
          <div class="col-sm-12 col-md-5">
            <absence-day-field
              id="absence-end-date"
              name="day"
              :form="form"
              :required="true"
              v-if="absenceTypeHour"
            />
          </div>
        </div>
        <div class="row" v-if="absenceTypeHour">
          <div class="col" v-if="absenceFromWorkShift">
            <absence-hour-for-full-shift-field
              hourTypeName="hourType"
              hourStartName="hourStart"
              hourEndName="hourEnd"
              :form="form"
              :required="true"
              :workShiftHour="workShiftHour"
              v-if="$isset(workShiftHour)"
              ref="hourForFullShift"
            />
          </div>
          <div class="col" v-else>
            <absence-hour-with-full-day-hours
              hourTypeName="hourType"
              hourStartName="hourStart"
              hourEndName="hourEnd"
              :form="form"
              :required="true"
              ref="hourForFullDay"
              v-if="$isset(workShiftHour)"
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <absence-file-field id="absence-type" name="attachment" :form="form" />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <absence-comment-field
              id="absence-comment"
              name="comments"
              :form="form"
              :required="commentRequired"
            />
          </div>
        </div>

        <div class="row">
          <div class="col text-right">
            <vue-button
              :title="$t('pages.permission.absences.send_request')"
              class="btn-primary send-button"
              :loading="isLoading"
              @click="sendRequest"
            />
          </div>
        </div>
      </div>
    </form>

    <success-info-modal-component />
  </article>
</template>

<script>
import {
  SucFormHasError,
  Form,
  Options,
  VueButton,
  SUC_SELECT_CHANGED_EVENT,
} from "@/components/form";

import { ModalAlert } from "@/components/modal";
import { RequiredRule, FormRule } from "@/components/form/data/rules";
import { SuccessInfoModalComponent } from "@/pages/components";
import {
  AbsenceStartDayField,
  AbsenceEndDayField,
  AbsenceCommentField,
  AbsenceTypeField,
  AbsenceHourForFullShiftField,
  AbsenceHourWithFullDayHours,
  AbsenceDayField,
  AbsenceFileField,
} from "@/pages/employee/permission/absences/fields";
import { WorkShiftHour } from "@/pages/responsible/planing/workshift/data";
import SkSpinner from "@/components/SkSpinner";

import apiAppointment from "@/api/appointment";
import apiUser from "@/api/user";

import { isAfter, isEqual, getHours, getMinutes, setHours, setMinutes } from "date-fns";

import { ABSENCE_CREATED_EVENT } from "./index";

import { mapGetters, mapState } from "vuex";
import * as Sentry from "@sentry/vue";
import { ABSENCE_TYPE_UNIT_DAY, ABSENCE_TYPE_UNIT_HOUR } from "@/pages/components/constants";

export default {
  components: {
    SuccessInfoModalComponent,
    AbsenceStartDayField,
    AbsenceEndDayField,
    AbsenceCommentField,
    AbsenceTypeField,
    AbsenceDayField,
    AbsenceHourForFullShiftField,
    AbsenceHourWithFullDayHours,
    AbsenceFileField,
    VueButton,
    SucFormHasError,
    SkSpinner,
  },
  data() {
    return {
      isLoading: false,
      form: new Form({
        data: {
          day: null,
          dateStart: null,
          dateEnd: null,
          comments: null,
          typeAppointment: null,
          hourType: null,
          hourStart: null,
          hourEnd: null,
          attachment: null,
        },
        options: new Options().add("typeAppointment", "code"),
      }),
      workShiftHour: null,
    };
  },
  computed: {
    ...mapGetters("auth", [
      "employeeCode",
      "isCurrentRoleEmployee",
      "isCurrentRoleResponsible",
      "absenceFromWorkShift",
    ]),
    ...mapState("auth", ["auth"]),
    absenceTypeDay() {
      if (this.$isset(this.form.typeAppointment)) {
        if (this.form.typeAppointment.unitOfMeasureCode === ABSENCE_TYPE_UNIT_DAY) {
          return true;
        }
      }
      return false;
    },
    absenceTypeHour() {
      if (this.$isset(this.form.typeAppointment)) {
        if (this.form.typeAppointment.unitOfMeasureCode === ABSENCE_TYPE_UNIT_HOUR) {
          return true;
        }
      }
      return false;
    },
    hourTypePartialDay() {
      return this.form.hourType != 0;
    },
    absenceTypeRecognized() {
      if (this.$isset(this.form.typeAppointment)) {
        if (
          [ABSENCE_TYPE_UNIT_DAY, ABSENCE_TYPE_UNIT_HOUR].includes(
            this.form.typeAppointment.unitOfMeasureCode
          )
        ) {
          return true;
        }
      }
      return false;
    },
    commentRequired() {
      return this.$isset(this.form.typeAppointment)
        ? this.form.typeAppointment.commentRequired
        : false;
    },
    fileRequired() {
      return this.$isset(this.form.typeAppointment)
        ? this.form.typeAppointment.fileRequired
        : false;
    },
    fieldsRules() {
      let rules = [
        new RequiredRule({
          name: "typeAppointment",
          errorMessage: this.$t("components.fields.rules.required"),
        }),
      ];

      if (this.absenceTypeHour) {
        rules.push(
          new RequiredRule({
            name: "day",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
      }

      if (this.absenceTypeDay) {
        rules.push(
          new RequiredRule({
            name: "dateStart",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
        rules.push(
          new RequiredRule({
            name: "dateEnd",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
      }

      if (this.commentRequired) {
        rules.push(
          new RequiredRule({
            name: "comments",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
      }

      if (this.fileRequired) {
        rules.push(
          new RequiredRule({
            name: "attachment",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
      }

      return rules;
    },
    formRules() {
      let rules = [];
      if (this.absenceTypeDay) {
        rules.push(
          new FormRule({
            rule: (x) => {
              return isAfter(x.dateEnd, x.dateStart) || isEqual(x.dateEnd, x.dateStart);
            },
            errorMessage: this.$t("pages.permission.wrong_period_date"),
          })
        );
      }

      if (this.absenceTypeHour && this.hourTypePartialDay) {
        rules.push(
          new FormRule({
            rule: (x) => {
              return isAfter(x.hourEnd, x.hourStart);
            },
            errorMessage: this.$t("pages.permission.wrong_hours"),
          })
        );
      }

      return rules;
    },
    selectChangedEvent() {
      return SUC_SELECT_CHANGED_EVENT;
    },
  },
  watch: {
    "form.day"(val) {
      if (this.$isset(val)) {
        this.getMyWorkShiftHourByDate();
      } else {
        this.workShiftHour = null;
      }
    },
    "form.typeAppointment"() {
      this.form.day = null;
      this.form.dateStart = null;
      this.form.dateEnd = null;
      this.form.comments = null;
      this.form.hourType = null;
      this.form.hourStart = null;
      this.form.hourEnd = null;
      this.form.attachment = null;
    },
  },
  methods: {
    clear() {
      this.form.reset();
    },
    typeAppointmentChanged(val) {
      this.form.typeAppointment = this.$isset(val) ? val.code : null;
    },
    prepareData() {
      let data = this.form.data();
      let request = new FormData();
      request.append("employeeCode", this.employeeCode);
      request.append("typeAppointment", data.typeAppointment);

      if (this.absenceTypeHour) {
        let startHour = getHours(this.form.hourStart);
        let startMinut = getMinutes(this.form.hourStart);
        let endHour = getHours(this.form.hourEnd);
        let endMinut = getMinutes(this.form.hourEnd);

        let dateStart = setMinutes(setHours(this.$parseDate(data.day), startHour), startMinut);
        let dateEnd = setMinutes(setHours(this.$parseDate(data.day), endHour), endMinut);

        request.append("dateEnd", this.form.formatDate(dateEnd));
        request.append("dateStart", this.form.formatDate(dateStart));
      } else {
        request.append("dateEnd", data.dateEnd);
        request.append("dateStart", data.dateStart);
      }
      if (this.$isset(data.comments)) {
        request.append("comments", data.comments);
      }

      if (this.$isset(data.attachment)) {
        request.append("attachment", data.attachment);
      }
      return request;
    },
    sendRequest() {
      let fieldsRules = [].concat(this.fieldsRules);
      let formRules = [];
      if (this.absenceTypeHour) {
        if (this.absenceFromWorkShift && this.$refs.hourForFullShift) {
          fieldsRules = fieldsRules.concat(this.$refs.hourForFullShift.fieldsRules);
        } else {
          if (this.$refs.hourForFullDay) {
            fieldsRules = fieldsRules.concat(this.$refs.hourForFullDay.fieldsRules);
          }
        }
      }
      formRules = [].concat(this.formRules);

      this.form.setFieldsRules(fieldsRules);
      this.form.setFormRules(formRules);

      //get
      if (this.form.validate()) {
        let data = this.prepareData();

        Sentry.setContext("sendData", {
          ...this.form.data(),
          employeeCode: this.employeeCode,
        });

        this.isLoading = true;
        apiAppointment
          .createAppointment(data)
          .then(() => {
            this.$toasts.success(this.$t("pages.permission.absences.operation_completed"));
            EventBus.fire(ABSENCE_CREATED_EVENT);
            this.form.reset();
          })
          .catch((error) => {
            if (error.message.toLowerCase() === "network error") {
              ModalAlert.error({ message: this.$t("components.messages.network_error") });
            } else {
              Sentry.captureException(error);
              let errorMessage = this.$t("components.messages.error");
              if (error?.response?.data?.errors) {
                errorMessage = error.response.data.errors[""][0];
              }
              console.error(errorMessage);
              ModalAlert.error({ message: errorMessage });
            }
          })
          .then(() => {
            this.isLoading = false;
          });
      }
    },
    getMyWorkShiftHourByDate() {
      this.isLoading = true;
      apiUser
        .getMyWorkShiftHourByDate({ day: this.form.day })
        .then((response) => {
          this.workShiftHour = new WorkShiftHour().parse(response.data);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status == 404) {
            this.form.day = null;
            ModalAlert.error({ message: this.$t("pages.permission.cant_use_date") });
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped type="scss">
.send-button {
  border-radius: $border-radius;
  height: 50px;
}
</style>
