<template>
  <div class="cell-body">
    <template v-if="isEmpty">
      <span class="padding"></span>
    </template>
    <template v-else-if="isOne">
      <absence-justified-day-legend
        class="padding"
        :withTitle="false"
        v-if="hasJustified"
        @click="appointmentClicked(cell.data[0])"
      />
      <absence-unjustified-day-legend
        class="padding"
        :withTitle="false"
        v-else-if="hasUnjustified"
        @click="appointmentClicked(cell.data[0])"
      />
      <absence-confirm-waiting-day-legend
        class="padding"
        :withTitle="false"
        v-else-if="hasWaitingConfirmation"
        @click="appointmentClicked(cell.data[0])"
      />
      <absence-rejected-day-legend
        class="padding"
        :withTitle="false"
        v-else-if="hasRejected"
        @click="appointmentClicked(cell.data[0])"
      />
      <sick-day-legend
        class="padding"
        :withTitle="false"
        v-else-if="hasSick"
        @click="appointmentClicked(cell.data[0])"
      />

      <absence-rejected-day-legend class="padding" :withTitle="false" v-else />
    </template>
    <template v-else>
      <div class="dropdown">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img src="@/assets/IC_Vacaciones_Pendiente.png" alt="type" />
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a
            class="dropdown-item"
            href="#"
            v-for="item in items"
            :key="item.key"
            @click.prevent="appointmentClicked(item)"
          >
            {{ permissionId(item) }} {{ item.causeOfAbsenceDescription }}
            <!-- - -->
            <!-- {{ pemissionStatus(item) }} -->
            (
            <formatted-date-component :val="item.dateStart" /> -
            <formatted-date-component :val="item.dateEnd" /> )
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { CalendarCell } from "@/components/calendar";
import { FormattedDateComponent } from "@/components/formatted";
import {
  AbsenceConfirmWaitingDayLegend,
  AbsenceJustifiedDayLegend,
  AbsenceUnjustifiedDayLegend,
  AbsenceRejectedDayLegend,
  SickDayLegend,
} from "@/pages/components/day-legend";
import { ABSENCE_CLICKED_EVENT } from "./index";

export default {
  components: {
    FormattedDateComponent,
    AbsenceConfirmWaitingDayLegend,
    AbsenceJustifiedDayLegend,
    AbsenceUnjustifiedDayLegend,
    AbsenceRejectedDayLegend,
    SickDayLegend,
  },
  props: {
    cell: {
      type: CalendarCell,
      required: true,
    },
  },
  computed: {
    isEmpty() {
      return this.cell.isEmpty;
    },
    isOne() {
      return this.cell.data.length == 1;
    },
    hasSick() {
      return this.$isset(this.cell.data.find((x) => x.isTypeFilterSick()));
    },
    hasWaitingConfirmation() {
      return this.$isset(this.cell.data.find((x) => x.isAbsenceConfirmWaiting()));
    },
    hasJustified() {
      return this.$isset(this.cell.data.find((x) => x.isAbsenceJustified()));
    },
    hasUnjustified() {
      return this.$isset(this.cell.data.find((x) => x.isAbsenceUnjustified()));
    },
    hasRejected() {
      return this.$isset(this.cell.data.find((x) => x.isAbsenceRejected()));
    },
    items() {
      return this.cell.data;
    },
  },
  methods: {
    appointmentClicked(appointment) {
      this.$emit(ABSENCE_CLICKED_EVENT, appointment);
      EventBus.fire(ABSENCE_CLICKED_EVENT, appointment);
    },
    pemissionStatus(item) {
      if (item.isAbsenceRejected()) return this.$t("pages.permission.absences.rejected");
      else if (item.isAbsenceNeedMeeting())
        return this.$t("pages.permission.absences.need_meeting");
      else return this.$t("pages.permission.absences.confirm_waiting");
    },
    permissionId(item) {
      return item.isPermission() ? `#${item.id}` : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.padding {
  padding: 8px;
}
.cell-body {
  min-width: 63px;
  min-height: 40px;
}
</style>
>
