import axios from "axios";
import { buildUrl } from "@/config";
import { formatDate } from "@/utils/functions";

export default {
  auth({ name, password }) {
    return axios.post(buildUrl("api/v1/user/auth"), {
      name,
      password,
    });
  },

  getAppConfiguration({ code }) {
    var url = `${process.env.VUE_APP_SETTINGS_SERVICES_ENDPOINT}/${code}`;
    return axios.get(url);
  },

  recoverPassword({ name }) {
    return axios.post(buildUrl("api/v1/user/password-recover"), {
      name,
    });
  },

  whoAmI() {
    return axios.get(buildUrl("api/v1/user/who-am-i"));
  },

  whoisUser(employeeCode) {
    return axios.get(buildUrl(`api/v1/user/who-is-user/${employeeCode}`));
  },

  myProfilePaymentUpdate(data) {
    return axios.post(buildUrl("api/v1/user/profile/payment"), data);
  },

  myProfileGeneralUpdate(data) {
    return axios.post(buildUrl("api/v1/user/profile/general"), data);
  },

  myProfileDirectionAndContactUpdate(data) {
    return axios.post(buildUrl("api/v1/user/profile/direction-and-contact"), data);
  },

  myProfilePersonalUpdate(data) {
    return axios.post(buildUrl("api/v1/user/profile/personal"), data);
  },

  getResponsibleEmployees() {
    return axios.get(buildUrl("api/v1/user/responsible-employees"));
  },

  getSwiftCodes() {
    return axios.get(buildUrl("api/v1/user/swift-code"));
  },

  /**
   *
   * @param {String} code
   * @param {Date} from
   * @param {Date} to
   * @returns {Promise<AxiosResponse<any>>}
   */
  getWorkHours({ from, to }) {
    const params = new URLSearchParams([
      ["DateStart", formatDate(from)],
      ["DateEnd", formatDate(to)],
    ]);
    return axios.get(buildUrl(`api/v1/plan/my-work-hours`), { params });
  },

  /**
   *
   * @param {Date} day
   * @returns {Promise<AxiosResponse<any>>}
   */
  getMyWorkShiftHourByDate({ day }) {
    return axios.get(buildUrl(`api/v1/plan/my-work-shift-hours-by-day/${formatDate(day)}`));
  },
};
