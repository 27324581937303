import AbsenceCalendarComponent from "@/pages/employee/permission/absences/components/AbsenceCalendarComponent.vue";
import AbsenceCreateFormComponent from "@/pages/employee/permission/absences/components/AbsenceCreateFormComponent.vue";
import AbsenceShowModal from "@/pages/employee/permission/absences/components/AbsenceShowModal.vue";

const ABSENCE_CLICKED_EVENT = "absence-clicked-event";
const ABSENCE_SHOW_EDIT_EVENT = "absence-show-edit-event";
const ABSENCE_SHOW_CREATE_EVENT = "absence-show-create-event";
const ABSENCE_SHOW_CONFIRM_DELETE_EVENT = "absence-show-confirm-delete-event";

const ABSENCE_CREATED_EVENT = "absence-created-event";
const ABSENCE_UPDATED_EVENT = "absence-updated-event";

export {
  ABSENCE_CLICKED_EVENT,
  ABSENCE_SHOW_EDIT_EVENT,
  ABSENCE_SHOW_CREATE_EVENT,
  ABSENCE_CREATED_EVENT,
  ABSENCE_UPDATED_EVENT,
  ABSENCE_SHOW_CONFIRM_DELETE_EVENT,
  AbsenceShowModal,
  AbsenceCalendarComponent,
  AbsenceCreateFormComponent,
};
