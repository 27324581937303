<template>
  <vue-modal
    :modal-title="$t('pages.permission.data')"
    :showSpinner="isLoading"
    :showModal="showModal"
    :showPrimaryFooterButton="false"
    :showSecondaryFooterButton="false"
    @[closeEvent]="onCloseButtonClicked"
  >
    <template #body>
      <div v-if="detail">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="label-description">{{ $t("pages.permission.status") }}</label>
              <div>
                <absence-justified-day-legend class="m-1" v-if="detail.isAbsenceJustified()" />
                <absence-unjustified-day-legend class="m-1" v-if="detail.isAbsenceUnjustified()" />
                <absence-confirm-waiting-day-legend
                  class="m-1"
                  v-if="detail.isAbsenceConfirmWaiting()"
                />
                <absence-rejected-day-legend class="m-1" v-if="detail.isAbsenceRejected()" />
                <sick-day-legend class="m-1" v-if="detail.isTypeFilterSick()" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <suc-text-form-show-field
              :title="$t('pages.permission.absences.type_absenses')"
              :value="detail.causeOfAbsenceDescription"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <suc-text-form-show-field
              :title="$t('pages.permission.absences.start_date')"
              :value="detail.dateStart"
            >
              <template #default="props">
                <formatted-date-component :val="props.value" :withTime="true" />
              </template>
            </suc-text-form-show-field>
          </div>
          <div class="col">
            <suc-text-form-show-field
              :title="$t('pages.permission.absences.end_date')"
              :value="detail.dateEnd"
            >
              <template #default="props">
                <formatted-date-component :val="props.value" :withTime="true" />
              </template>
            </suc-text-form-show-field>
          </div>
        </div>
        <div class="row" v-if="detail.comments">
          <div class="col">
            <suc-text-form-show-field
              :title="$t('pages.permission.absences.comments')"
              :value="detail.comments"
            />
          </div>
        </div>
        <div class="row" v-if="detail.attachments.length > 0">
          <div class="col">
            <div class="form-group">
              <label class="label-description"> {{ $t("pages.permission.attachments") }}</label>
              <div>
                <div class="description">
                  <a
                    target="_blank"
                    href="#"
                    v-for="attachment in detail.attachments"
                    :key="attachment.key"
                    @click.prevent="downLoadAttachment(attachment)"
                    >{{ attachment.name }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="detail.canRemove()">
          <div class="col-md-12 text-right">
            <vue-button
              class="btn-danger button my-1 mx-2"
              :title="$t('components.actions.remove')"
              @click.prevent="removeAbsence"
            >
              <template #body-icon>
                <i class="fas fa-trash-alt"></i>
              </template>
            </vue-button>
          </div>
        </div>
      </div>
    </template>
  </vue-modal>
</template>

<script>
import { SucTextFormShowField, VueButton } from "@/components/form";
import { ModalAlert } from "@/components/modal";
import { FormattedDateComponent } from "@/components/formatted";
import {
  AbsenceConfirmWaitingDayLegend,
  AbsenceJustifiedDayLegend,
  AbsenceUnjustifiedDayLegend,
  AbsenceRejectedDayLegend,
  SickDayLegend,
} from "@/pages/components/day-legend";

import VueModal, {
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "@/components/modal";

import { ABSENCE_CLICKED_EVENT, ABSENCE_UPDATED_EVENT } from "./index";

import { FinalAbsence } from "@/pages/components/data";

import api from "@/api/appointment";
import * as Sentry from "@sentry/vue";
import { mapGetters } from "vuex";

export default {
  components: {
    VueModal,
    VueButton,
    SucTextFormShowField,
    FormattedDateComponent,
    AbsenceConfirmWaitingDayLegend,
    AbsenceJustifiedDayLegend,
    AbsenceUnjustifiedDayLegend,
    AbsenceRejectedDayLegend,
    SickDayLegend,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      absence: null,
      detail: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["employeeCode"]),
    primaryEvent() {
      return PRIMARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    secondaryEvent() {
      return SECONDARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
  },
  watch: {
    showModal(val) {
      if (val) {
        this.init();
      }
    },
  },
  created() {
    EventBus.listen(ABSENCE_CLICKED_EVENT, (absence) => {
      this.absence = absence;
      this.showModal = true;
    });
  },
  beforeDestroy() {
    EventBus.off(ABSENCE_CLICKED_EVENT);
  },
  methods: {
    init() {
      this.isLoading = true;
      this.detail = null;
      api
        .getDetailAbsence({
          employeeCode: this.employeeCode,
          id: this.absence.id,
          source: this.absence.sourceCode,
        })
        .then((response) => {
          this.detail = new FinalAbsence().parse(response.data);
        })
        .catch((error) => {
          if (error.message.toLowerCase() === "network error") {
            this.$toasts.error(this.$t("components.messages.network_error"));
          } else {
            //unsigned error
            Sentry.captureException(error);
            this.$toasts.error(this.$t("components.messages.error"));
            this.onCloseButtonClicked();
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    downLoadAttachment(attachment) {
      this.isLoading = true;
      api
        .downloadAttachment({
          employeeCode: attachment.employeeCode,
          reference: attachment.reference,
        })
        .then((response) => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${attachment.name}.${attachment.fileExtension}`;
          link.click();
          this.isLoading = false;
        })
        .catch((error) => {
          ModalAlert.error({ message: "Error" });
          console.error(error.response);
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    removeAbsence() {
      if (this.detail.canRemove()) {
        ModalAlert.confirm({
          message: this.$t("pages.permission.delete_confirmation"),
          confirmButtonText: this.$t("components.actions.confirm"),
          cancelButtonText: this.$t("components.actions.close"),
        }).then(async (response) => {
          if (response.isConfirmed) {
            api
              .removeAppointment(this.detail.id)
              .then(() => {
                this.showModal = false;
                this.$toasts.success(this.$t("components.messages.success"));
                EventBus.fire(ABSENCE_UPDATED_EVENT);
                this.onCloseButtonClicked();
              })
              .catch((error) => {
                Sentry.captureException(error);
                let errorMessage = this.$t("components.messages.error");
                if (error?.response?.data?.errors) {
                  errorMessage = error.response.data.errors[""][0];
                }
                console.error(errorMessage);
                this.$toasts.error(errorMessage);
              });
          }
        });
      }
    },
    onSecondaryButtonClicked() {
      this.clear();
    },
    onCloseButtonClicked() {
      this.clear();
      this.showModal = false;
    },
    clear() {
      this.hourValue = "";
    },
  },
};
</script>
