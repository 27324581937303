<template>
  <suc-calendar-component
    :type="type"
    :cellData="cellData"
    @[getCalendarDataEvent]="init"
    :isLoading="isLoading"
  >
    <template #cell="props">
      <suc-calendar-item-component :cell="props.cell">
        <template #body="props">
          <absence-content-component :cell="props.cell" />
        </template>
      </suc-calendar-item-component>
    </template>
    <template #foot>
      <tr>
        <td colspan="7">
          <div class="row">
            <div class="col d-flex flex-wrap">
              <absence-justified-day-legend class="m-1" />
              <absence-unjustified-day-legend class="m-1" />
              <absence-confirm-waiting-day-legend class="m-1" />
              <absence-rejected-day-legend class="m-1" />
              <sick-day-legend class="m-1" />
            </div>
          </div>
        </td>
      </tr>
    </template>
  </suc-calendar-component>
</template>

<script>
import SucCalendarComponent, {
  SucCalendarItemComponent,
  GET_CALENDAR_DATA,
  CellData,
} from "@/components/calendar";
import { DATE_PERIOD_MONTH } from "@/components/form";
import AbsenceContentComponent from "./AbsenceContentComponent.vue";

import { addDays, differenceInCalendarDays, isEqual } from "date-fns";

import api from "@/api/appointment";
import { FinalAbsence } from "@/pages/components/data";

import { ABSENCE_CREATED_EVENT, ABSENCE_UPDATED_EVENT } from "./index";

import {
  AbsenceConfirmWaitingDayLegend,
  AbsenceJustifiedDayLegend,
  AbsenceUnjustifiedDayLegend,
  AbsenceRejectedDayLegend,
  SickDayLegend,
} from "@/pages/components/day-legend";

export default {
  components: {
    SucCalendarComponent,
    SucCalendarItemComponent,
    AbsenceContentComponent,
    AbsenceConfirmWaitingDayLegend,
    AbsenceJustifiedDayLegend,
    AbsenceUnjustifiedDayLegend,
    AbsenceRejectedDayLegend,
    SickDayLegend,
  },
  created() {
    EventBus.listen([ABSENCE_CREATED_EVENT, ABSENCE_UPDATED_EVENT], () => {
      this.init({ start: this.dateStart, end: this.dateEnd });
    });
  },
  beforeDestroy() {
    EventBus.off([ABSENCE_CREATED_EVENT, ABSENCE_UPDATED_EVENT]);
  },
  data() {
    return {
      cellData: [],
      absences: [],
      rawData: [],
      dateStart: null,
      dateEnd: null,
      isLoading: false,
    };
  },
  computed: {
    type() {
      return DATE_PERIOD_MONTH;
    },
    getCalendarDataEvent() {
      return GET_CALENDAR_DATA;
    },
  },
  methods: {
    async init({ start, end }) {
      this.isLoading = true;
      this.dateStart = start;
      this.dateEnd = end;
      await this.loadData(start, end);
      this.prepare();
      this.isLoading = false;
    },
    async loadData(start, end) {
      let absences = [];
      const { data } = await api.getMyAbsences({ start, end });
      for (let item of data) {
        absences.push(new FinalAbsence().parse(item));
      }
      this.absences = absences;
      this.rawData = data;
    },
    prepare() {
      let data = [];
      for (let item of this.absences) {
        const diffDays = differenceInCalendarDays(item.dateEnd, item.dateStart);
        for (let i = 0; i <= diffDays; i++) {
          let day = addDays(item.dateStart, i);
          day.setHours(0, 0, 0, 0);
          let cell = data.find((x) =>
            isEqual(new Date(x.day.getFullYear(), x.day.getMonth(), x.day.getDate()), day)
          );
          if (this.$isset(cell)) {
            cell.data.push(item);
          } else {
            data.push(new CellData({ day, data: [item] }));
          }
        }
      }
      this.cellData = data;
    },
  },
};
</script>

<style scoped></style>
